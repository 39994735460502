import React, { Component, Fragment } from "react";
import { Route, Routes } from "react-router";
import ProfilePage from "../pages/ProfilePage";
import NotFoundPage from "../pages/NotFoundPage";

class AppRoute extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Fragment>
        <Routes>
          <Route path=":name" element={<ProfilePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Fragment>
    );
  }
}

export default AppRoute;
