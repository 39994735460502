import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Button,
  ButtonGroup,
  Typography,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import WorkIcon from "@mui/icons-material/Work";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TwitterIcon from "@mui/icons-material/Twitter";
import VCard from "vcard-creator";
import FileSaver from "file-saver";

import { useParams } from "react-router-dom";

import staff from "../assets/profile/staff";

function Header(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div style={{ background: "#04262f", height: "380px" }}>
      <br />
      <br />
      <br />
      <Grid lg={12} item container>
        <Grid item lg={4} xs={12}></Grid>
        <Grid item lg={4} xs={12} justifyContent="center" alignItems="center">
          <Box
            sx={{
              height: "320px",
              margin: "0 auto",
              boxShadow: "0 -5px 40px 7px rgb(0 0 0 / 8%)",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {" "}
              <Grid xs>
                <br />
                {props.profile.firstname === "Hamizah" &&
                window.matchMedia("(max-width: 360px)").matches ? (
                  ""
                ) : (
                  <br />
                )}
                <br />
                <Avatar
                  alt={`${props.profile.firstname}
              &nbsp;
              ${props.profile.lastname}`}
                  src={props.profile.image}
                  sx={{ width: 100, height: 100, margin: "auto" }}
                />
                <br />
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ color: "white", textAlign: "center" }}
                >
                  {props.profile.firstname}
                  &nbsp;
                  {props.profile.lastname}
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  gutterBottom
                  sx={{ color: "white", textAlign: "center" }}
                >
                  {props.profile.jobtitle}
                </Typography>
                <br />
              </Grid>
              <Grid xs={12}>
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined light button group"
                  sx={{
                    width: "100%",
                  }}
                >
                  <Button
                    style={{
                      minWidth: "33.33%",
                      color: "#fff",
                      border: "1px solid #fff",
                      height: "70px",
                    }}
                    size="large"
                    href={`tel:+6${props.profile.phoneno}`}
                  >
                    <LocalPhoneIcon />
                    &nbsp;CALL
                  </Button>
                  <Button
                    style={{
                      minWidth: "33.33%",
                      color: "#fff",
                      border: "1px solid #fff",
                    }}
                    size="large"
                    href={`mailto:${props.profile.email}`}
                  >
                    <EmailIcon />
                    &nbsp;EMAIL
                  </Button>
                  <Button
                    style={{
                      minWidth: "33.33%",
                      color: "#fff",
                      border: "1px solid #fff",
                    }}
                    size="large"
                    onClick={handleOpen}
                  >
                    <LocationOnIcon />
                    &nbsp;DIRECTIONS
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={4} xs={12}></Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grid item container lg={12}>
          <Grid item lg={4} xs={12}></Grid>
          <Grid item lg={4} xs={12}>
            <Fade in={open}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "150",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "white",
                  boxShadow: "0 -5px 40px 7px rgb(0 0 0 / 8%)",
                  p: 5,
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Location
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Medini 6, Nusajaya, Johor
                </Typography>
                <br />
                <Button
                  variant="outlined"
                  color="success"
                  size="large"
                  href="https://goo.gl/maps/gQHQNc92JPABaDxaA"
                >
                  SHOW DIRECTIONS
                </Button>
              </Box>
            </Fade>
          </Grid>
          <Grid item lg={4} xs={12}></Grid>
        </Grid>
      </Modal>
    </div>
  );
}

function Content(props) {
  return (
    <Grid item container lg={12}>
      <Grid item lg={4} xs={12}></Grid>
      <Grid item lg={4} xs={12}>
        <Box
          sx={{
            height: "auto",
            margin: "0 auto",
            boxShadow: "0 -5px 40px 7px rgb(0 0 0 / 8%)",
          }}
        >
          <List sx={{ width: "100%" }}>
            <div>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#04262f" }}>
                    <LocalPhoneIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={props.profile.phoneno}
                  secondary="Mobile"
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#04262f" }}>
                  <EmailIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={props.profile.email} secondary="Email" />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#04262f" }}>
                  <WorkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={props.profile.company}
                secondary={props.profile.jobtitle}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#04262f" }}>
                  <LocationOnIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Medini 6, Nusajaya" secondary="Johor" />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#04262f" }}>
                  <LanguageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={props.profile.url}
                secondary="Website"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(props.profile.url, "_blank");
                }}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar></ListItemAvatar>
              <ListItemText
                primary="Social Media"
                secondary={
                  <React.Fragment>
                    <Grid container sx={{ margin: "20px auto" }}>
                      <Grid
                        xs={2}
                        sx={{ justifyContent: "center", display: "grid" }}
                      >
                        <Avatar
                          sx={{ bgcolor: "#25D366", cursor: "pointer" }}
                          onClick={() => {
                            window.open(
                              `https://www.wasap.my/60${props.profile.phoneno}`,
                              "_blank"
                            );
                          }}
                        >
                          <WhatsAppIcon />
                        </Avatar>
                      </Grid>
                      {props.profile.linkedin === "" ? (
                        ""
                      ) : (
                        <Grid
                          xs={2}
                          sx={{ justifyContent: "center", display: "grid" }}
                        >
                          <Avatar
                            sx={{ bgcolor: "#0e76a8", cursor: "pointer" }}
                            onClick={() => {
                              window.open(props.profile.linkedin, "_blank");
                            }}
                          >
                            <LinkedInIcon />
                          </Avatar>
                        </Grid>
                      )}
                      {props.profile.facebook === "" ? (
                        ""
                      ) : (
                        <Grid
                          xs={2}
                          sx={{ justifyContent: "center", display: "grid" }}
                        >
                          <Avatar
                            sx={{ bgcolor: "#3b5998", cursor: "pointer" }}
                            onClick={() => {
                              window.open(props.profile.facebook, "_blank");
                            }}
                          >
                            <FacebookIcon />
                          </Avatar>
                        </Grid>
                      )}
                      {props.profile.instagram === "" ? (
                        ""
                      ) : (
                        <Grid
                          xs={2}
                          sx={{ justifyContent: "center", display: "grid" }}
                        >
                          <Avatar
                            sx={{ bgcolor: "#cd486b", cursor: "pointer" }}
                            onClick={() => {
                              window.open(props.profile.instagram, "_blank");
                            }}
                          >
                            <InstagramIcon />
                          </Avatar>
                        </Grid>
                      )}
                      {props.profile.github === "" ? (
                        ""
                      ) : (
                        <Grid
                          xs={2}
                          sx={{ justifyContent: "center", display: "grid" }}
                        >
                          <Avatar
                            sx={{ bgcolor: "#171515", cursor: "pointer" }}
                            onClick={() => {
                              window.open(props.profile.github, "_blank");
                            }}
                          >
                            <GitHubIcon />
                          </Avatar>
                        </Grid>
                      )}
                      {props.profile.youtube === "" ? (
                        ""
                      ) : (
                        <Grid
                          xs={2}
                          sx={{ justifyContent: "center", display: "grid" }}
                        >
                          <Avatar
                            sx={{ bgcolor: "#c4302b", cursor: "pointer" }}
                            onClick={() => {
                              window.open(props.profile.youtube, "_blank");
                            }}
                          >
                            <YouTubeIcon />
                          </Avatar>
                        </Grid>
                      )}
                      {props.profile.pinterest === "" ? (
                        ""
                      ) : (
                        <Grid
                          xs={2}
                          sx={{ justifyContent: "center", display: "grid" }}
                        >
                          <Avatar
                            sx={{ bgcolor: "#c8232c", cursor: "pointer" }}
                            onClick={() => {
                              window.open(props.profile.pinterest, "_blank");
                            }}
                          >
                            <PinterestIcon />
                          </Avatar>
                        </Grid>
                      )}
                      {props.profile.twitter === "" ? (
                        ""
                      ) : (
                        <Grid
                          xs={2}
                          sx={{ justifyContent: "center", display: "grid" }}
                        >
                          <Avatar
                            sx={{ bgcolor: "#00acee", cursor: "pointer" }}
                            onClick={() => {
                              window.open(props.profile.twitter, "_blank");
                            }}
                          >
                            <TwitterIcon />
                          </Avatar>
                        </Grid>
                      )}
                    </Grid>
                    <Button
                      variant="contained"
                      color="success"
                      size="large"
                      sx={{ display: "block", width: "100%" }}
                      onClick={() => downloadVcard(props.profile)}
                    >
                      DOWNLOAD VCARD
                    </Button>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
          <br />
        </Box>
      </Grid>
      <Grid item lg={4} xs={12}></Grid>
    </Grid>
  );
}

async function toDataUrl(src, outputFormat, size) {
  return new Promise(async (resolve, reject) => {
    var img = new Image();
    img.onload = () => {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      var calwidth;
      var calheight;

      if (img.width < img.height) {
        var fixsize = (2 / 3) * size;
        calwidth = fixsize;
        calheight = (fixsize / img.width) * img.height;
      } else {
        calwidth = size;
        calheight = (size / img.width) * img.height;
      }

      canvas.height = calheight;
      canvas.width = size;
      ctx.drawImage(
        img,
        canvas.width / 2 - calwidth / 2,
        0,
        calwidth,
        calheight
      );
      dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.crossOrigin = "anonymous";
    img.src = src;
  });
}

async function downloadVcard(profile) {
  const myVCard = new VCard();
  const lastname = profile.lastname;
  const firstname = profile.firstname;
  const dataURL = await toDataUrl(profile.image, "JPEG", "200");
  myVCard
    // Add personal data
    .addName(lastname, firstname)
    // Add work data
    .addCompany(profile.company)
    .addJobtitle(profile.jobtitle)
    .addEmail(profile.email)
    .addPhoneNumber(profile.phoneno, "WORK")
    .addAddress(
      profile.address[0],
      profile.address[1],
      profile.address[2],
      profile.address[3],
      profile.address[4]
    )
    .addURL(profile.url)
    .addPhoto(dataURL.replace("data:image/png;base64,", ""), "JPEG");
  const blob = new Blob([myVCard.toString()], {
    type: "text/vcard;charset=utf-8",
  });
  FileSaver.saveAs(blob, `${profile.name}.vcf`);
}

function StaffNotExist() {
  return (
    <div
      style={{
        background: "#04262f",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        overflowY: "hidden",
      }}
    >
      <h1>Staff Not Exist!</h1>
    </div>
  );
}

export default function ProfilePage() {
  let params = useParams();
  const [profile, setProfile] = useState([]);

  useEffect(() => {
    const index = staff.findIndex((i) => i.name === params.name);
    index >= 0 ? setProfile(staff[index]) : setProfile(null);
  }, [params.name]);
  return (
    <Fragment>
      {profile != null ? (
        <div>
          <Header profile={profile} />
          <Content profile={profile} />{" "}
        </div>
      ) : (
        <div>
          <StaffNotExist />
        </div>
      )}
      <br />
    </Fragment>
  );
}
