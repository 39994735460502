const staff = [
  {
    name: "iskandar",
    firstname: "Dr. Iskandar",
    lastname: "Illyas",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "CEO",
    email: "iskandar@holisticslab.my",
    phoneno: "0126022044",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/iskandar.jpg",
    linkedin: "https://www.linkedin.com/in/mohdiskandar/",
    facebook: "https://www.facebook.com/iskandar.illyas/",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "zuhra",
    firstname: "Dr. Zuhra",
    lastname: "Jainuda",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "CMO",
    email: "zuhra@holisticslab.my",
    phoneno: "0176022044",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/zuhra.png",
    linkedin: "",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "rahmat",
    firstname: "Rahmat",
    lastname: "Harun",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "CTO",
    email: "rahmatharun@holisticslab.my",
    phoneno: "0104440304",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/rahmat.jpg",
    linkedin: "https://www.linkedin.com/in/rahmatharun",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "hamizah",
    firstname: "Hamizah",
    lastname: "Hazmi",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Senior Executive, Sales & Customer Relations",
    email: "hamizahfarhanah@holisticslab.my",
    phoneno: "0177917154",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image:
      "https://www.holisticslab.my/wp-content/uploads/2024/05/hamizah-e1717056830826.png",
    linkedin: "",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "irsyad",
    firstname: "Irsyad",
    lastname: "Kamil",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Project Manager",
    email: "irsyadkamil@holisticslab.my",
    phoneno: "0176754281",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/irsyad.jpg",
    linkedin: "https://www.linkedin.com/in/irsyadkamil/",
    facebook: "",
    instagram: "https://www.instagram.com/mikr96/",
    github: "https://github.com/mikr96",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "zahirah",
    firstname: "Zahirah",
    lastname: "Zahir",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "HR and Corporate Communications Executive",
    email: "zahirahzahir@holisticslab.my",
    phoneno: "0127939378",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/zahirah.png",
    linkedin: "https://www.linkedin.com/in/siti-nur-zahirah-zahir-zz2802/",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "syaza",
    firstname: "Syaza",
    lastname: "Nadhierah",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Marketing Executive",
    email: "syazanadhierah@holisticslab.my",
    phoneno: "01164009316",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/syaza.png",
    linkedin: "",
    facebook: "https://www.facebook.com/syaza.nadhierah.1238/",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "zulaikha",
    firstname: "Zulaikha",
    lastname: "Ibrahim",
    company: "Holistics Lab Sdn Bhd",
    jobtitle:
      "Product Management and Development Executive (Consultation Service)",
    email: "nurzulaikha@holisticslab.my",
    phoneno: "0137738870",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/zulaikha.jpg",
    linkedin: "",
    facebook: "https://www.facebook.com/zulaikha.eka.31",
    instagram: "https://www.instagram.com/zu_eykaa/",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "liyana",
    firstname: "Liyana",
    lastname: "Mohtaram",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Senior Executive Marketing",
    email: "nurliyanafaqihah@holisticslab.my",
    phoneno: "0108831268",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/liyana.jpeg",
    linkedin: "",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "puvana",
    firstname: "Puvana",
    lastname: "Mohanadas",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Account Executive",
    email: "puvanamohanadas@holisticslab.my",
    phoneno: "0167788427",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/puva.jpg",
    linkedin: "",
    facebook: "https://www.facebook.com/profile.php?id=100007287765928",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "athari",
    firstname: "Athari",
    lastname: "Shafiee",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Front End & Graphic",
    email: "atharishafiee@holisticslab.my",
    phoneno: "01127559089",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/athari.jpg",
    linkedin: "https://www.linkedin.com/in/athari-shafiee-989b83109/",
    facebook: "https://www.facebook.com/athari.shafiee",
    instagram: "https://www.instagram.com/athari_ayie/",
    github: "",
    pinterest: "",
    youtube: "https://www.youtube.com/channel/UCYApV55YXITIi4HXtnBfXow",
    twitter: "https://twitter.com/Ayie_Athari",
  },
  {
    name: "amirah",
    firstname: "Amirah",
    lastname: "Zulaikha",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Senior Executive, Product Management and Development",
    email: "amirazulaikha@holisticslab.my",
    phoneno: "0133012928",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/amirah.jpg",
    linkedin: "",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "aaina",
    firstname: "Aaina",
    lastname: "Hamdan",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Data Analyst",
    email: "aainahamdan@holisticslab.my",
    phoneno: "0137891423",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Nusajaya",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/aaina.jpg",
    linkedin: "https://www.linkedin.com/in/aainahamdan",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "amirul",
    firstname: "Amirul",
    lastname: "Hassan",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Executive, Sales & Customer Relations",
    email: "amirulhassan@holisticslab.my",
    phoneno: "0196883876",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Iskandar Puteri",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/amirul.jpg",
    linkedin: "",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "izzuddin",
    firstname: "Wan Mohd",
    lastname: "Izzuddin",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Software Engineer",
    email: "wanizzuddin@holisticslab.my",
    phoneno: "0197684496",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Iskandar Puteri",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/izzuddin.jpg",
    linkedin: "",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "hakim",
    firstname: "Hakim",
    lastname: "Yasin",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Python Developer",
    email: "nurhakimyasin@holisticslab.my",
    phoneno: "0176723541",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Iskandar Puteri",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/hakim.jpg",
    linkedin: "",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "hasyir",
    firstname: "Mohammad",
    lastname: "Hasyir",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Software Engineer",
    email: "mohammadhasyirxd@holisticslab.my",
    phoneno: "0137988533",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Iskandar Puteri",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/hasyir.jpg",
    linkedin: "",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "halimaton",
    firstname: "Halimaton",
    lastname: "Saadiah",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "UI / UX Designer",
    email: "halimatonsaadiah@holisticslab.my",
    phoneno: "0135464788",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Iskandar Puteri",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/halimaton.jpg",
    linkedin: "",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "syakirah",
    firstname: "Nur",
    lastname: "Syakirah",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Product Management and Development Executive (IT Products)",
    email: "nsyakirah@holisticslab.my",
    phoneno: "01157213586",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Iskandar Puteri",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/syakirah.jpg",
    linkedin: "",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "ninna",
    firstname: "Ninna",
    lastname: "Diyana",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Executive, Sales & Customer Relations",
    email: "ninnadiyana@holisticslab.my",
    phoneno: "0182224708",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Iskandar Puteri",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "http://bizcard.holisticslab.my/images/ninna.jpg",
    linkedin:
      "https://www.linkedin.com/in/ninna-diyana-mohd-dani-goh-29bb05215/",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
  {
    name: "hidayah",
    firstname: "Hidayah",
    lastname: "Tahir",
    company: "Holistics Lab Sdn Bhd",
    jobtitle: "Corporate Communication Executive",
    email: "hidayahtahir@holisticslab.my",
    phoneno: "0133986847",
    address: [
      "BG-F-05, Medini 6",
      "Jalan Medini Sentral 5",
      "Medini",
      "79250 Iskandar Puteri",
      "Johor",
    ],
    url: "https://www.holisticslab.my",
    image: "https://www.holisticslab.my/wp-content/uploads/2023/03/hidayah.png",
    linkedin: "",
    facebook: "",
    instagram: "",
    github: "",
    pinterest: "",
    youtube: "",
    twitter: "",
  },
];

module.exports = staff;
